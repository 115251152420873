import React, { useEffect } from "react"
import Meta from "../utilities/seo"

const Ele = () => {
  useEffect(() => {
    window.location.href = "https://invest.myxeno.com/privacy-policy"
  }, [])
  return (
    <>
      <Meta title="Privacy Policy" />
    </>
  )
}

export default Ele
